// check if using IE
function isIE() {
  var userAgent = window.navigator.userAgent.toLowerCase();
  if ( userAgent.indexOf( 'msie' ) !== -1 || userAgent.indexOf( 'trident' ) !== -1 ) {
    return true;
  }
  return false;
}

let ieAlert = document.querySelector('.js-ieAlert');

if(isIE() === true){
  ieAlert.style.display = 'flex';
  let body = document.getElementsByTagName('body')[0];
  body.classList.add('is-fixed');
}


import collapse from './modules/collapse';
import menu from './modules/menu';
import smoothScroll from './modules/smoothScroll';

collapse();
menu();
smoothScroll();


let hideToBottom = document.querySelector('.js-hideToBottom');
let headerBar = document.querySelector('.js-headerBar');
document.onscroll = function() {

  // Hide element when reaches the bottom of page
  let currentPosition = window.innerHeight + window.scrollY; 
  let bodyHeight = document.body.clientHeight; 
  if (currentPosition > bodyHeight || currentPosition == bodyHeight) {
    hideToBottom.style.display='none';
  } else {
    hideToBottom.style.display='flex';
  }

  // Header with background-color after scrolling
  if(window.scrollY > 0) {
    headerBar.classList.add('is-active');
  } else {
    headerBar.classList.remove('is-active');
  }
}


// switch video source between pc and sp
if(document.querySelector('.js-video')) {
  let video = document.querySelector('.js-video');
  window.addEventListener('load', ()=>{
  
    let source = document.createElement('source');
    let url;
  
    if(window.screen.width > 767) {
      url = template_directory + '/assets/video/video_pc.mp4';
    } else {
      url = template_directory + '/assets/video/video_sp.mp4';
    }
  
    source.setAttribute('src', url);
  
    video.appendChild(source);
    video.play();
  })
}  

// Table Scroll Hint
new ScrollHint('.js-scrollable');


// AOS
AOS.init();


// pagination cutimization
let prevLink = document.querySelector('.previouspostslink');
let nextLink = document.querySelector('.nextpostslink');


if(prevLink) {
  prevLink.classList.add('c-pagination_prev');
  prevLink.classList.add('c-btn');
  prevLink.classList.add('c-btn--gradient');
  
  let arw = document.createElement('span');
  arw.classList.add('c-arw');
  arw.classList.add('c-arw--white');

  arw.classList.add('c-arw--re');
  prevLink.appendChild(arw);  
}

if(nextLink) {
  nextLink.classList.add('c-pagination_next');
  nextLink.classList.add('c-btn');
  nextLink.classList.add('c-btn--gradient');

  let arw = document.createElement('span');
  arw.classList.add('c-arw');
  arw.classList.add('c-arw--white');

  nextLink.appendChild(arw);  
}


// form
let submitBtnFake = document.querySelector('#submitBtnFake');
let submitBtn = document.querySelector('.submit');
let backBtnFake = document.querySelector('#backBtnFake');
let backBtn = document.querySelector('.back');

if(submitBtnFake){
  submitBtnFake.addEventListener('click', ()=>{
    submitBtn.click();
  })
  
  backBtnFake.addEventListener('click', ()=>{
    backBtn.click();
  })
  
  let agree = document.querySelector('#agree');
  let privacyCheck = document.querySelector(".privacy");
  let privacyCheck_c = document.querySelector("#agree .c-check_c");

  if(privacyCheck){
    privacyCheck.checked = false;
  
    agree.addEventListener('click', ()=>{
    
      checkPrivacy(privacyCheck, privacyCheck_c);
    
    })
  }
}

function checkPrivacy(privacyCheck, privacyCheck_c){
  if(privacyCheck.checked == false){
    privacyCheck.checked = true;
    privacyCheck_c.classList.remove('c-check_c--unchecked');
  }else {
    privacyCheck.checked = false;
    privacyCheck_c.classList.add('c-check_c--unchecked');
  }
}

function autoChangeFormBtnText(){
  let pathname = window.location.pathname;
  if(pathname.includes('check')){
    submitBtnFake.innerHTML = '<div class="c-btn c-btn--gradient"><span class="c-arw c-arw--white"></span>送信する</div>'; 
  }
}
autoChangeFormBtnText();