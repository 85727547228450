export default function () {
  let body = document.querySelector('body')
  let menuTrigger = document.querySelector('.js-menuTrigger')
  let menuTarget = document.querySelector('.js-menuTarget')

  menuTrigger.addEventListener('click', ()=>{
    body.classList.toggle('is-fixed');
    menuTrigger.classList.toggle('is-active');
    menuTarget.classList.toggle('is-active');
  })
}